import { useState, useEffect } from "react";

import './CoursesDetails.css';

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Svg_Arrow, Svg_Play } from "services/SvgFile";

import { colorIcon } from "fixedData";

import { Link } from "react-router-dom";

export default function Page_CoursesDetails(props){

    const [ idModule, setIdModule ] = useState(GetListPag('currentPageIdModule'));
    const [ idClasse, setIdClasse ] = useState(GetListPag('currentPageIdClasse'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ typeVideo, setTypeVideo ] = useState(InitialData('type_video'));
    const [ video, setVideo ] = useState(InitialData('video'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ download, setDownload ] = useState(InitialData('download'));

    const [ listModule, setListModule ] = useState(ShowModule());
    const [ showInfCourse, setShowInfCourse ] = useState('text');

    function InitialData(type){
        const newData = GetDataPage('courses').find(item => item.id == props.idCourse);
        if(newData){
            if(idModule === 0){
                if(type === 'download'){
                    return [];
                }
                return newData[type];
            }else {
                const newModule = newData['classe'].find(item => item.id == idModule);
                if(newModule){
                    const newClasse = newModule['list'].find(item => item.id == idClasse);
                    if(newClasse){
                        if(type === 'title'){
                            return newData['title'] + " - " + newClasse['title'];
                        }else {
                            return newClasse[type];
                        }
                    }
                    if(type === 'download'){
                        return [];
                    }
                    return '';
                }
                if(type === 'download'){
                    return [];
                }
                return '';
            }
        }
        if(type === 'download'){
            return [];
        }
        return "";
    }

    function ShowModule(){
        const newData = GetDataPage('courses').find(item => item.id == props.idCourse);        
        if(newData){
            return newData['classe'];
        }
        return [];
    }

    function ShowVideo(){
        switch (typeVideo) {
            case 'Vimeo':
                let linkVimeo = video.split('/');
                return (
                    <div className="aspect_video">
                        <iframe className="div_video" src={ "https://player.vimeo.com/video/" + linkVimeo[3] } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />
                    </div>
                );
                
            case 'Youtube':
                let linkYoutube = video.split('=');
                return (
                    <div className="aspect_video">
                        <iframe className="div_video" src={ "https://www.youtube.com/embed/" + linkYoutube[1] } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />
                    </div>
                );
        }
    }

    function CourseProgress(id){
        const newData = [];
        return newData.length;
    }

    function OpenMoodule(id, index, qtdClasse, status){
        const newData = [...listModule];
        newData[index]['status'] = status;
        setListModule(newData);

        let accordion = document.getElementById(id);
        if(status){
            accordion.style.maxHeight = (accordion.scrollHeight + (qtdClasse * 22) + 80) + "px";
            accordion.style.padding = "8px 8px";
            
        }else {
            accordion.style.maxHeight = null; 
            accordion.style.padding = "0px 8px";
        }
    }

    function OpenClasse(module, classe){
        SetListPag('currentPageIdModule', module);
        SetListPag('currentPageIdClasse', classe);
    }

    useEffect(()=>{
        RegisterListPag('currentPageIdModule', setIdModule);
        RegisterListPag('currentPageIdClasse', setIdClasse);

        return ()=>{
            UnRegisterListPag('currentPageIdModule', setIdModule);
            UnRegisterListPag('currentPageIdClasse', setIdClasse);
        };
    }, []);

    useEffect(()=>{
        setTitle(InitialData('title'));
        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setText(InitialData('text'));
        setDownload(InitialData('download'));
    }, [idModule, idClasse]);

    return(
        <div className="page_content page_courses_details">
            <div className="container">
                <div className="title_course">
                    <Link to="/login" className="return" onClick={ ()=>{ props.ReturnIndex(); } }>
                        <Svg_Arrow className="icons" color={ colorIcon } />
                        Voltar
                    </Link>
                    <span>|</span>
                    <div className="">{ title }</div>
                </div>
                <div className="inf_details">
                    <div className="show_course">
                        { ShowVideo() }
                    </div>
                    <div className="list_module">
                        {
                            listModule.map((elem, index)=>{                                
                                return(
                                    <div className="show_module" key={ index }>
                                        <div className="module" onClick={ ()=>{ OpenMoodule("accordion_" + index, index, elem.list.length, !elem.status); } }>
                                            <div className="">{ elem.name }</div>
                                            <div className="">{ CourseProgress(elem.id) }/{ elem.list.length }</div>
                                        </div>
                                        <div className="div_list_classes" id={ "accordion_" + index }>
                                            {
                                                elem.list.map((elem_1, index_1)=>{
                                                    return(
                                                        <div className={ idClasse === elem_1.id ? "show_inf_course course_id" : "show_inf_course" } key={ index }>
                                                            <div className="">
                                                                <input type="checkbox" className="input_checkbox" value="true" onChange={ (e)=>{  } } checked={ false } />
                                                            </div>
                                                            <div className="title">{ elem_1.title }</div>
                                                            <div className="div_play" onClick={ ()=>{ OpenClasse(elem.id, elem_1.id) } }>
                                                                <Svg_Play className="icons" color={ colorIcon } />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="show_details_course">
                    <div className="details_text" onClick={ ()=>{ setShowInfCourse('text') } }>
                        Descrição
                        {
                            showInfCourse === "text" ? <div className="opt_active" /> : null
                        }                        
                    </div>
                    {
                        download.length > 0 ? 
                            <div className="details_text" onClick={ ()=>{ setShowInfCourse('download') } }>
                                Download
                                {
                                    showInfCourse === "download" ? <div className="opt_active" /> : null
                                }
                            </div>
                        : null
                    }
                </div>
                <div className="show_details_course">
                    {
                        showInfCourse === "text" ?
                        <div className="div_text" dangerouslySetInnerHTML={ { __html: text.replaceAll('&#34;', '"') } } />
                        :
                        // <div className="list_download">
                        // </div>
                        null
                    }
                </div>
            </div>
        </div>
    )
}