import './TextImg.css';

import { SetListPag } from "interface/Page";

import { Link } from "react-router-dom";

export default function Comp_TextImg(props){
    
    function ShowText(){
        return(
            <div className={ "div_text align_" + props.data['align_text'] }>
                <div className="title" style={ { color: props.dataConfig['color_title'] } }>{ props.data['title'] }</div>
                <div className="text" dangerouslySetInnerHTML={ { __html: props.data['text'].replaceAll('&#34;', '"') } } />
                {
                    props.data['btn'].length > 0 ?
                        <div className="btn">
                            {
                                props.data['btn'].map((elem, index)=>{
                                    return(
                                        <Link to={ "/" + elem.page } className="show_btn" onClick={ ()=>{ SetListPag('page', elem.page); props.ReturnIndex(); } } key={ index } style={ { background: props.dataConfig['color_btn'] } }>
                                            { elem.title }
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    : null
                }
            </div>
        )
    }

    function ShowImg(type){
        return(
            <div className={ props.data['align_file'] === "center" ? "div_img align_img" : "div_img" }>
                <img alt="img" src={ props.data['file'] } />
            </div>
        )
    }

    function ShowData(){
        switch (props.data['align_file']) {
            case "left": case "center":
                return(
                    <div className="show_data_text">
                        { ShowImg() }
                        { ShowText() }
                    </div>
                );

            case "right":
                return(
                    <div className="show_data_text">
                        { ShowText() }
                        { ShowImg() }
                    </div>
                );
        }
    }
    
    return(
        <div className="TextImg show_page" id={ props.data['id_href'] }>
            {
                props.data['bg_img'] !='' ? 
                    <div className="bg_image" style={ { backgroundImage: 'url("' + props.data['bg_img'] + '")' } } />
                : null
            }
            <div className="container">
                {
                    ShowData()
                }
            </div>
        </div>
    )
}