import { useState, useEffect } from "react";

import './Courses.css';

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Page_Courses(props){

    const [ search, setSearch ] = useState('');
    const [ courses, setCourses ] = useState(GetDataPage('courses'));

    function InputSearch(value){
        const newList = [];
        if(value){
            GetDataPage('courses').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setCourses(duplicate);
        }else {
            setCourses(GetDataPage('courses'));
        }
        setSearch(value);
    }

    function OpenCourse(title, text, type_video, video){
        SetModalData('InfCourse', { "title": title, "text": text, "type_video": type_video, "video": video });
        SetModalState('InfCourse', true)
    }

    return(
        <div className="page_content page_courses">
            <div className="container">
                <div className="div_search">
                    <input type="text" className="input_search" onChange={ (e)=>{ InputSearch(e.target.value) } } value={ search } placeholder="digite aqui sua pesquisa..." />
                </div>

                <div className="div_courses">
                    {
                        courses.length > 0 ?
                            courses.map((elem, index)=>{
                                return(
                                    <div className="inf_course" key={ index }>
                                        <div className="img_cover" style={ { backgroundImage: 'url("' + elem.cover + '")' } } />
                                        <div className="details_course">
                                            <div className="name_course">{ elem.title }</div>
                                            <div className="description_course" dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                            <div className="color_text" />
                                            <div className="div_purchase">
                                                <div className="btn_course" style={ { background: props.dataConfig['color_btn'] } }>Adicionar ao carrinho</div>
                                                <div className="btn_course" style={ { background: props.dataConfig['color_btn'] } } onClick={ ()=>{ OpenCourse(elem.title, elem.text, elem.type_video, elem.video) } }>Informações sobre o curso</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        : <div className="no_data">Nenhum dado encontrado...</div>
                    }
                </div>
            </div>
        </div>
    )
}