import React, { useState, useEffect } from "react";

import './InfCourse.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";
import { Svg_Close } from "services/SvgFile";

export default function PopUP_InfCourse(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ title, setTitle ] = useState('');
    const [ text, setText ] = useState('');

    function ClosePopUp(){
        SetModalState('InfCourse', false);
    }

    function ShowVideo(){
        switch (modalData.type_video) {
            case 'Vimeo':
                let linkVimeo = modalData.video.split('/');
                return (
                    <div className="aspect_video">
                        <iframe className="div_video" src={ "https://player.vimeo.com/video/" + linkVimeo[3] } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />
                    </div>
                );
                
            case 'Youtube':
                let linkYoutube = modalData.video.split('=');
                return (
                    <div className="aspect_video">
                        <iframe className="div_video" src={ "https://www.youtube.com/embed/" + linkYoutube[1] } frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen="" />
                    </div>
                );
        }
    }

    useEffect(()=>{
        RegisterModalData('InfCourse', setModaldata);
        RegisterModalObserver('InfCourse', setShowPopUp);
    }, []);

    useEffect(()=>{        
        if(showPopUp){
            setTitle(modalData.title);
            setText(modalData.text);
            document.body.style.overflow = "hidden";
        }else {
            document.body.style.overflow = null;
        }
    }, [showPopUp]);

    return(
        (showPopUp ?
            <div className="PopUp">
                <div className="all InfCourse">
                    <div className="div_data">
                        <div className="type_div">
                            <div className="">{ modalData.title }</div>
                            <div className="" onClick={ ()=>{ ClosePopUp(); } }>
                                <Svg_Close className="icons" color="#f00000" />
                            </div>
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <div className="show_video">
                                { ShowVideo() }
                            </div>
                            <div className="title">{ title }</div>
                            <div className="text" dangerouslySetInnerHTML={ { __html: text.replaceAll('&#34;', '"') } } />
                            <div className="btn_course" style={ { background: props.dataConfig['color_btn'] } }>Adicionar ao carrinho</div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    )
};
