import { useState } from "react";

import './Home.css';

import { GetDataPage } from "interface/Data";

import Comp_TextImg from "components/TextImg";
import Comp_SlideShow_Img from "components/SlideShow_Img";
import Comp_SlideShow_Video from "components/SlideShow_Video";

export default function Page_Home(props){
    const [ dataPage, setDataPage ] = useState(InitialData());

    function InitialData(){
        const newData = GetDataPage('site');        
        if(newData){
            return newData;
        }
        return [];
    }

    function ShowCurrent(origin, type, data, index){
        switch (origin) {
            case 0:
                if(type === 0){
                    return <Comp_SlideShow_Img data={ data } key={ index } />;
                }else {
                    return <Comp_SlideShow_Video data={ data } key={ index } />;
                }
                
            default:
                return <Comp_TextImg data={ data } dataConfig={ props.dataConfig } key={ index } ReturnIndex={ props.ReturnIndex } />;
        }
    }

    return(
        <div className="page_content">
            {
                dataPage.map((elem, index)=>{
                    return ShowCurrent(elem.origin, elem.type, elem, index);
                })
            }
        </div>
    )
}