import './SlideShow_Video.css';

export default function Comp_SlideShow_Video(props){

    return(
        <div className="SlideShow_Video">
            <div className="show_img">
                <div className="container">
                    {
                        props.data['title'] == "" ? null :
                        <div className="title_slide title" data-text={ props.data['title'] }>
                            { props.data['title'] }
                        </div>
                    }
                    {
                        props.data['subtitle'] == "" ? null :
                        <div className="title_slide title subtitle" data-text={ props.data['subtitle'] }>
                            { props.data['subtitle'] }
                        </div>
                    }
                </div>
                <div className="video_background">
                    <video id="show_video" className="video" loop={ true } autoPlay="autoplay" controls={ false } muted>
                        <source src={ props.data['file'] } type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}