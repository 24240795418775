import { useState, useEffect } from "react";

import Cookies from 'universal-cookie';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { GetDataPage } from "interface/Data";

import { Access } from "services/Access";
import { Svg_Arrow_2 } from "services/SvgFile";
import { typeCookieEmail, typeCookiePassw } from "fixedData";

import Comp_Header from "components/Header";
import Comp_Footer from "components/Footer";

import PopUP_InfCourse from "components/PopUp/InfCourse";
import PopUP_RecoverPass from "components/PopUp/RecoverPass";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

import Page_Faq from "page/Faq";
import Page_Home from "page/Home";
import Page_Login from "page/Login";
import Page_Logado from "page/Logado";
import Page_Others from "page/Others";
import Page_Recover from "page/Recover";
import Page_Courses from "page/Courses";
import Page_CoursesDetails from "page/CoursesDetails";
import Page_Register from "page/Register";

export default function App(props) {

    const cookies  = new Cookies();
    const [ statusLogin, setStatusLogin ] = useState(false);

    const [ loading, setLoading ] = useState(false);
    const [ dataConfig, setDataConfig ] = useState(GetDataPage('settings'));
    const [ subPage, setSubPage ] = useState(ShowSubPage());
    const [ url, setUrl ] = useState(window.location.href.split("/"));

    function CheckedPage(){
        if(url[3]){
            let sepInf = url[3].split('='); 
            
            return <Route exact path={ "/recover=" + sepInf[1] } element={ <Page_Recover code={ sepInf[1] } dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } /> } />;
        }
        return '';
    }

    function ShowSubPage(){
        const newData = [];
        GetDataPage('site').map((elem, index)=>{
            elem.btn.map((elem_1, index_1)=>{
                if(newData.find(item => item.page == elem_1.page)){ }else {
                    newData.push(elem_1);
                }
            });
        });
        return newData;
    }

    function ReturnIndex(){
        const scrollInterval = setInterval(() => {            
            const element = document.getElementById('return_up');
            if (element) {
                clearInterval(scrollInterval);   
                let yOffset = -60; 
                if(window.innerWidth > 768){
                    yOffset += -10;
                } 
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }          
        }, 200);
    }

    useEffect(()=>{
        if(cookies.get(typeCookieEmail) || cookies.get(typeCookiePassw)){
            Access('return', cookies.get(typeCookieEmail), cookies.get(typeCookiePassw), ()=>{ setStatusLogin(true); }, ()=>{ setStatusLogin(false); });
        }
    }, []);

    useEffect(()=>{
        if(loading){
            document.body.style.overflow = "hidden";
        }else {
            document.body.style.overflow = null;
        }

        window.addEventListener("scroll", ()=>{
            if(window.scrollY >= 60){
                document.getElementById('return').classList.add('return_index');
                document.getElementById('return').classList.remove('return_close');
            }else {
                document.getElementById('return').classList.add('return_close');
                document.getElementById('return').classList.remove('return_index');
            }
        });
    }, [loading]);

    return(
        <Router>
            <div className="return_close" id="return" onClick={ ()=>{ ReturnIndex() } }>
                <Svg_Arrow_2 className="icon_up" color="#2d3c53" />
            </div>

            <div className={ loading ? "return_data_save div_loading" : "return_data_save" }>
                <div className="dot-spinner">
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                </div>
            </div>

            <Comp_Header dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } statusLogin={ statusLogin } />

            <Routes>
                <Route exact path="/" element={ <Page_Home dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } /> } />

                <Route exact path="*" element={ <Page_Home dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } /> } />

                <Route exact path="/faq" element={ <Page_Faq dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } /> } />

                <Route exact path="/cursos" element={ <Page_Courses dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } /> } />

                <Route exact path="/login" element={ 
                    statusLogin ? 
                    <Page_Logado dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } setStatusLogin={ setStatusLogin } />
                    : 
                    <Page_Login dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } setStatusLogin={ setStatusLogin } /> 
                } />

                <Route exact path="/novo_cadastro" element={ <Page_Register dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } /> } />

                {
                    GetDataPage('courses').map((elem, index)=>{                        
                        return <Route exact path={ "/" + elem.url } element={ <Page_CoursesDetails ReturnIndex={ ReturnIndex } setLoading={ setLoading } idCourse={ elem.id } /> } key={ index } />
                    })
                }  
                
                { CheckedPage() }

                {
                    subPage.map((elem, index)=>{
                        return(
                            <Route exact path={ "/" + elem.page } element={ <Page_Others dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } dataPage={ elem } /> } key={ index } />
                        )
                    })
                }
            </Routes>

            <Comp_Footer dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } />

            <PopUP_RecoverPass dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } />
            <PopUP_InfCourse dataConfig={ dataConfig } ReturnIndex={ ReturnIndex } setLoading={ setLoading } />
            <PopUP_ReturnResponse setLoading={ setLoading } />
        </Router>
    );
}