import './Others.css';

import Comp_TextImg from "components/TextImg";

export default function Page_Others(props){
    
    return(
        <div className="page_content">
            <Comp_TextImg data={ props.dataPage } dataConfig={ props.dataConfig } ReturnIndex={ props.ReturnIndex } />
        </div>
    )
}