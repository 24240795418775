
let DataPage = {   
    "name_menu" : [],
    "settings" : {},
    "site" : [],
    "faq" : []
};

let NotifyDataPage = {
    "name_menu" : [],
    "settings" : [],
    "site" : [],
    "faq" : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["name_menu"].forEach(element => { element(value['name_menu']); });
    NotifyDataPage["settings"].forEach(element => { element(value['settings']); });
    NotifyDataPage["site"].forEach(element => { element(value['site']); });
    NotifyDataPage["faq"].forEach(element => { element(value['faq']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}