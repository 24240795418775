let UserData = {
    "id" : 0,
    "file" : '',
    "name" : '',
    "email" : '',
    
    "phone" : '',
    "cep" : '',
    "city" : '',
    "state" : '',
    "neighborhood" : '',
    "street" : '',
    "number" : '',
    "complement" : '',

    "courses" : []
};

let NotifyUserData = {
    "id" : [],
    "file" : [],
    "name" : [],
    "email" : [],
    
    "phone" : [],
    "cep" : [],
    "city" : [],
    "state" : [],
    "neighborhood" : [],
    "street" : [],
    "number" : [],
    "complement" : [],

    "courses" : []
}

export function SetUserData(key, value){
    UserData[key] = value;
    NotifyUserData[key].forEach(element => {
        element(value);
    });
}
export function GetUserData(key){
    return UserData[key];
}
export function RegisterUserData(key, value){
    if(!NotifyUserData[key]){
        NotifyUserData[key] = [];
    }
    NotifyUserData[key].push(value);
}

export function UnRegisterUserPage(key, callback){
    if(NotifyUserData[key].length > 0){
        NotifyUserData[key] = NotifyUserData[key].filter((item) => {
            return item !== callback;
        });
    }
}
