import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from 'axios';

import { SetListData } from 'interface/Data';

import { urlPhp } from 'fixedData';

const root = ReactDOM.createRoot(document.getElementById('root'));

const data = new FormData();
data.append('type_page', 'show_data_initial');

Axios({
    url : urlPhp + 'inc/list_data_site.php',
    mode : 'no-cors',
    method : 'POST',
    data : data
})
.then(response => {
    console.log(response.data);
    SetListData(response.data);

    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
});