import './Footer.css';

import Page_Contact from "page/Contact";

export default function Comp_Footer(props){

    return(
        <>
            <Page_Contact dataConfig={ props.dataConfig } ReturnIndex={ props.ReturnIndex } setLoading={ props.setLoading } />
            
            <footer className="">
                © 2024 fast evolution - Todos os direitos reservados
            </footer>
        </>
    )
}