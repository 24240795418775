import { useState, useEffect, useRef } from "react";

import Cookies from 'universal-cookie';

import './Logado.css';

import { colorIcon, cookiesRegister, typeCookieEmail, typeCookiePageId, typeCookiePageIdClasse, typeCookiePageIdModule, typeCookiePassw } from "fixedData";

import { Svg_User } from "services/SvgFile";
import { Reg_SaveUser } from "services/Register";
import { cepMask, phoneMask } from "services/Mask";

import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";

import { Link } from "react-router-dom";

export default function Page_Logado(props){
    
    const cookies = new Cookies(); 

    const [ optSelected, setOptSelected ] = useState('courses');

    const [ file, setFile ] = useState(GetUserData('file'));
    const [ name, setName ] = useState(GetUserData('name'));
    const [ email, setEmail ] = useState(GetUserData('email'));
    const [ pass, setPass ] = useState('');
    const [ phone, setPhone ] = useState(GetUserData('phone'));
    const [ cep, setCep ] = useState(GetUserData('cep'));
    const [ city, setCity ] = useState(GetUserData('city'));
    const [ state, setState ] = useState(GetUserData('state'));
    const [ neighborhood, setNeighborhood ] = useState(GetUserData('neighborhood'));
    const [ street, setStreet ] = useState(GetUserData('street'));
    const [ number, setNumber ] = useState(GetUserData('number'));
    const [ complement, setComplement ] = useState(GetUserData('complement'));
    
    const [ courses, setCourses ] = useState(GetUserData('courses'));

    function Logout(){  
        props.setStatusLogin(false);      
        cookies.remove(typeCookiePageId, '', '/', cookiesRegister);
        cookies.remove(typeCookiePageIdModule, '', '/', cookiesRegister);
        cookies.remove(typeCookiePageIdClasse, '', '/', cookiesRegister);

        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
    }

    function SaveFile(value){
        SaveData('', value);
    }

    function SaveData(event, value){
        props.setLoading(true);
        if(event){
            event.preventDefault();
            Reg_SaveUser(GetUserData('id'), file, name, email, pass, phone, cep, city, state, neighborhood, street, number, complement, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }else {
            Reg_SaveUser(GetUserData('id'), value, name, email, pass, phone, cep, city, state, neighborhood, street, number, complement, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "Informação registrada com sucesso!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        
    }

    function ShowInfUser(){
        if(optSelected === 'courses'){
            return(                
                <div className="container">
                    <div className="list_courses">
                        {
                            courses.length > 0 ?
                                courses.map((elem, index)=>{
                                    let infCourse = GetDataPage('courses').find(item => item.id == elem.id_course);
                                    return(
                                        <div className="inf_course" key={ index }>
                                            <div className="div_img" style={ { backgroundImage: 'url("' + infCourse.cover + '")' } } />
                                            <div className="name_course">{ infCourse.title }</div>

                                            <Link to={ "/" + infCourse.url } className="btn" onClick={ ()=>{ props.ReturnIndex(); } } style={ { background: props.dataConfig['color_btn'] } }>
                                                Visualizar
                                            </Link>
                                        </div>
                                    )
                                })
                            :
                            <div className="no_data">Nenhum curso adquirido até o momento!</div>
                        }
                    </div>
                </div>
            )

        }else if(optSelected === 'profile'){
            return(                
                <div className="container">
                    <div className="div_inf_user">
                        <div className="div_img_user">
                            {
                                GetUserData('file') === '' ?
                                    <Svg_User color={ colorIcon } className="icon_show_user" />
                                :
                                <div className="btn_show_img" style={ { backgroundImage: 'url("' + GetUserData('file') + '")' } } />
                            }
                            <label className="btn_user" style={ { background: props.dataConfig['color_btn'] } }>
                                <input type="file" onChange={ (e)=>{ SaveFile(e.target.files[0]) } } />
                                Alterar foto
                            </label>
                        </div>
                        <form className="div_show_inf" onSubmit={ SaveData }>
                            <div className="type_data">
                                <div className="name_type_data">Meus dados</div>
                                <div className="show_inf_input">
                                    <div className="div_input space_div">
                                        <div className="name_input">Nome*</div>
                                        <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } required={ true } />
                                    </div>
                                    <div className="div_input space_div">
                                        <div className="name_input">E-mail*</div>
                                        <input type="text" onChange={ (e)=>{ setEmail(e.target.value.replaceAll(' ', '')) } } value={ email } required={ true } />
                                    </div>
                                    <div className="div_input">
                                        <div className="name_input">Contato*</div>
                                        <input type="text" onChange={ (e)=>{ setPhone(e.target.value) } } value={ phoneMask(phone) } required={ true } />
                                    </div>
                                    <div className="div_input">
                                        <div className="name_input">Senha</div>
                                        <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } value={ pass } required={ false } />
                                    </div>
                                </div>
                            </div>
                            <div className="type_data">
                                <div className="name_type_data">Endereço</div>
                                <div className="show_inf_input">
                                    <div className="div_input">
                                        <div className="name_input">CEP</div>
                                        <input type="text" className="cep" onChange={ (e)=>{ setCep(e.target.value)  } } value={ cepMask(cep) } required={ false } placeholder="xxxxx-xx" />
                                    </div>
                                </div>
                                <div className="show_inf_input">
                                    <div className="div_input space_div">
                                        <div className="name_input">Cidade</div>
                                        <input type="text" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } maxLength={ 140 } required={ false } />
                                    </div>
                                    <div className="div_input">
                                        <div className="name_input">UF</div>
                                        <input type="text" className="uf" onChange={ (e)=>{ setState(e.target.value.replaceAll(' ', '')) } } value={ state } maxLength={ 3 } required={ false } />
                                    </div>
                                    <div className="div_input space_div">
                                        <div className="name_input">Bairro</div>
                                        <input type="text" className="" onChange={ (e)=>{ setNeighborhood(e.target.value) } } value={ neighborhood } maxLength={ 140 } required={ false } />
                                    </div>
                                    <div className="div_input space_div">
                                        <div className="name_input">Rua</div>
                                        <input type="text" className="" onChange={ (e)=>{ setStreet(e.target.value) } } value={ street } maxLength={ 140 } required={ false } />
                                    </div>
                                    <div className="div_input">
                                        <div className="name_input">Número</div>
                                        <input type="text" className="number" onChange={ (e)=>{ setNumber(e.target.value) } } value={ number } maxLength={ 140 } required={ false } placeholder="xxxxxx" />
                                    </div>
                                </div>
                                <div className="show_inf_input">
                                    <div className="div_input space_div">
                                        <div className="name_input">Complemento</div>
                                        <input type="text" className="" onChange={ (e)=>{ setComplement(e.target.value) } } value={ complement } required={ false } />
                                    </div>
                                </div>
                            </div>
                            <button className="btn_save" style={ { background: props.dataConfig['color_btn'] } }>Salvar</button>
                        </form>
                    </div>
                </div>
            )
        }
    }

    useEffect(()=>{

    }, []);

    return(
        <div className="page_content page_logado">
            <div className="div_inf_bg">
                <div className="container">
                    <div className="div_menu_opt_user">
                        <div className="div_opt" onClick={ ()=>{ setOptSelected('courses') } }>
                            Meus cursos
                            {
                                optSelected === "courses" ? 
                                    <div className="opt_active" />
                                : null 
                            }
                        </div>
                        <div className="div_opt" onClick={ ()=>{ setOptSelected('profile') } }>
                            Meus dados
                            {
                                optSelected === "profile" ? 
                                    <div className="opt_active" />
                                : null 
                            }
                        </div>
                        <div className="div_opt div_opt_" onClick={ ()=>{ Logout() } }>Sair</div>
                    </div>
                </div>
            </div>
            
            <div className="div_show_inf_user">
                { ShowInfUser() }
            </div>            
        </div>
    )
}