import './SlideShow_Img.css';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export default function Comp_SlideShow_Img(props){

    const SlideOpt = {
        infinite: true,
        indicators: true,
        arrows: true,
        duration: 3000
    };    

    return(
        <div className="SlideShow_Img">
            <Slide { ...SlideOpt }>
                {
                    props.data['slideshow'].map((elem, index)=>{                        
                        return(
                            <div className="each-slide-effect" key={ index }>
                                {
                                    elem.title == "" ? null :
                                    <div className="title_slide title" data-text={ elem.title }>
                                        { elem.title }
                                    </div>
                                }
                                <div style={ { backgroundImage: 'url("' + elem.file + '")' } } />
                            </div>
                        )
                    })
                }
            </Slide>
        </div>
    )
}