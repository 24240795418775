
// Cookies folder project
// export const cookiesRegister = '';
// export const urlPhp = 'http://localhost/React_JS/fastevolution/josemery/php/';

export const cookiesRegister = "https://josemery-dashboard.fastevolution.com.br/";
export const urlPhp = "https://josemery-php.fastevolution.com.br/";

// Cookies name
export const typeCookiePageId = 'josemery_site_id';
export const typeCookiePageIdModule = 'josemery_site_id_module';
export const typeCookiePageIdClasse = 'josemery_site_id_classe';

export const typeCookiePassw = 'josemery_site_passw';
export const typeCookieEmail = 'josemery_site_email';

export const colorIcon = '#2d3c53';
export const colorIconSecondary = '#ffc400';